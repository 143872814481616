
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-family: var(--base-font-family) !important;
  line-height: 24px;
  letter-spacing: -0.332143px;
  .content {
    height: calc(100% - 108px);
    overflow-y: auto;
    word-wrap: break-word;
  }
  h3 {
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    letter-spacing: -0.5px;
  }
  p,
  ul li {
    font-weight: 300;
  }

  ul {
    margin-bottom: 25px;
    margin-top: 20px !important;
  }
}
