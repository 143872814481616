
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  padding: 0px 15px;
  margin-top: -15px;
  text-align: left;

  .heading {
    font-family: var(--heading-font-family) !important;
    margin-bottom: 22px;
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    color: var(--primary);
    letter-spacing: -0.5px;
  }

  .title {
    margin-bottom: 20px;
    font-family: var(--base-font-family) !important;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #424876;
  }

  .disclaimer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 300;
  }

  .container {
    max-width: 440px;
    margin-top: 30px;
  }

  .mb-30,
  .mb-20 {
    margin-bottom: 30px !important;
  }

  .table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 30px;
    border: 1px solid rgba(231, 231, 236, 0.7);
    box-sizing: border-box;
    box-shadow: -4px 3px 25px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    tr {
      td {
        border-bottom: 1px solid #f0f0f0;
        padding: 16px 14px;
        color: var(--primary);
        &:first-child {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.3px;
          background: lighten($c-primary, 74%);
          width: 100px;
        }

        &:nth-child(2) {
          font-size: 14px;
        }

        &:last-child {
          text-align: center;
          padding-right: 6px;
          line-height: 6px;
          svg {
            fill: var(--secondary);
            width: 20px;
            cursor: pointer;
          }
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .isCorrect {
    display: flex;

    .input {
      margin: 0;
      margin-bottom: 15px;
    }

    .checkbox {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}
@media (max-width: 650px) {
  .wrapper {
    padding: 15px 0 0;
  }
}
