$c-primary: #3f4a5e;
$c-secandary: #3f4a5e;
$dots: false;
$version: 3.1;

$body-color-overrided: $c-primary;
$loading-bg-overrided: white;
$progress-bar-overided: $c-primary;
$dot-overrided: $c-primary;

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('#{$project-font-path}Rubik-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Verdana';
  src: url('#{$project-font-path}VerdanaPro-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;
