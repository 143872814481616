
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-family: var(--base-font-family) !important;
  line-height: 24px;
  letter-spacing: -0.332143px;

  h2 {
    font-size: var(--heading-font-size) !important;
  }
  ol {
    padding-left: 25px;
    counter-reset: my-awesome-counter;
    list-style: none;
    li {
      counter-increment: my-awesome-counter;
      position: relative;
      margin-bottom: 10px;
      line-height: 24px;
      letter-spacing: -0.332143px;

      strong {
        font-weight: 500;
        font-family: var(--base-font-family) !important;
      }
      &:before {
        content: counter(my-awesome-counter);
        color: #00aeb8;
        background: url(_img('icons/svg/li-success.svg')) no-repeat 0 0;
        position: absolute;
        top: 0;
        left: -28px;
        width: 25px;
        height: 25px;
        text-align: center;
        font-size: 10px;
      }
    }
  }
}
