
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding: 0 25px 0;

  h3 {
    text-align: center;
    font-family: var(--base-font-family) !important;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 16px;
  }

  button {
    max-width: 265px;
  }

  .swContainer {
    width: 100%;
    max-height: 225px;
    max-width: 320px;
    margin-top: 20px;
    //margin-bottom: 10px;

    img {
      display: inline-block;
      margin: auto;
      height: 100%;
      vertical-align: bottom;
      max-width: 285px;
    }
  }

  .dots {
    margin: 14px 0 0;
    padding: 0;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #f2f2f2;
      margin: 0 5px;

      &.active {
        background: var(--primary);
      }
    }
  }
}
