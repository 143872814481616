
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding-top: 15px;
  margin-top: -15px;
  text-align: center;

  .spinner {
    img {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    svg {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    .svg {
      fill: black;
    }
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    letter-spacing: -0.3px;
  }

  .content {
    //margin-top: 20px;
    font-size: 14px;
    text-align: center;
    img {
      display: block;
      margin: 0 auto 10px;
      max-width: 200px;
    }
  }
}
