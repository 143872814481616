
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.description {
  margin-top: 1.5rem;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.instructionList {
  margin-top: 39px;
}

.item {
  margin-bottom: 26px;
}

.instructionNumber {
  display: table-cell;
  width: 22px;
  height: 100%;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;

  color: #000;
}

.instructionText {
  display: table-cell;
  height: 100%;
  margin-left: 10px;
}

.hiddenInput {
  display: none;
}
