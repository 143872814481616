
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.warpper {
  min-height: 58px;
  display: block;
  z-index: 33;
  background: var(--page-background-color);
  //position: absolute;
  //top: 0;
  //left: 0;
  height: 82.7px;
  //right: 0;
  overflow: hidden;

  .img-container {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      height: 40px;
    }
  }

  .close-container {
    float: right;
    position: relative;
    top: 20px;
    cursor: pointer;
  }

  .langButton-white {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_white.svg'));
  }

  .langButton-black {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_black.svg'));
  }

  .langButton {
    position: absolute;
    border: 0;
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 20px 20px;
    top: 0;
    right: 0;
    background-position-y: center;
    background-position-x: center;
    margin-left: -15px;
  }
}

.isDark {
  background: #000 !important;
}

@media (max-width: 760px) {
  .warpper {
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
