
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: var(--page-background-color);

  .body {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    top: 0;
    bottom: 4.8125rem; //45+16*2=77
    left: 0;
    right: 0;
    overflow: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--page-padding-top) 1.5rem;
    flex: 1;
  }
  .content-force-fill-view-port {
    overflow: hidden;
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 4.8125rem; //45+16*2=77
    padding: 1rem 1.5rem; //16 24
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
  }

  .footerShadow {
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
  }
  .isDark {
    background: #000 !important;
  }
}

.isDark {
  background: #000 !important;
}
