
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/svic/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;

  margin-top: unset !important;
  margin-bottom: unset !important;

  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  .language-selector-content {
    width: 80%;
    background-color: var(--page-background-color);
    border-radius: 16px !important;
    overflow: hidden;
    box-shadow: none;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .title {
    color: black;
    font-size: 1.375rem;
    font-family: 'Radnika';
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .language-item-container-first {
    @extend .language-item-container;
    margin-top: 40px;
  }

  .language-item-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
  }

  .language-title {
    color: black;
    font-size: 1rem;
    font-family: 'Radnika';
    font-weight: normal;
  }
}
